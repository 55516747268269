@import './palette';

$themes: (
    'default': (
        background: palette-var('black-v2'),
        background-lite: palette-var('midnight'),
        border: palette-var('right-before-midnight'),
        box-shadow: palette-var('midnight-weak'),
        input-border: palette-var('cold-steel-medium'),
        input-focus-border: palette-var('cold-steel'),
        input-text: palette-var('white'),
        inverse-background: palette-var('white'),
        inverse-text: palette-var('white'),
        negative: palette-var('angry'),
        positive: palette-var('springtime'),
        progress: palette-var('sharp-gradient-tricolor'),
        card-backdrop: palette-var('midnight'),
        card-background: palette-var('before-midnight'),
        secondary-background: palette-var('right-before-midnight'),
        secondary-text: palette-var('cold-steel'),
        skeleton-shimmer: palette-var('midnight-weak'),
        skeleton: palette-var('cold-steel-opaque'),
        text: palette-var('white'),
        warning: palette-var('coal-mine-canary'),
        modal-overlay: palette-var('midnight-weak'),
        cyanora: palette-var('cyanora'),
        turquoise: palette-var('turquoise'),
        coal-mine-canary: palette-var('coal-mine-canary'),
        future-fuscia: palette-var('future-fuscia'),
        white: palette-var('white'),
        black: palette-var('black'),
        text-dim: palette-var('cold-steel'),
        transparent-background: palette-var('transparent-dark-grey'),
        header-background: palette-var('transparent-black-v2'),
        footer-background: palette-var('transparent-black-v2'),
    ),
    'off': (
        background: palette-var('off-white'),
        border: palette-var('cold-steel-weak'),
    ),
    'black': (
        primary: palette-var('future-fuscia'),
        primary-down: palette-var('future-fuscia'),
        tap-highlight: palette-var('future-fuscia'),
        secondary: palette-var('white'),
        tertiary: palette-var('transparent-dark-grey'),
        header-background: palette-var('transparent-black-v2'),
        footer-background: palette-var('transparent-black-v2'),
        input-border: palette-var('cold-steel-medium'),
        input-focus-border: palette-var('cold-steel'),
        input-text: palette-var('white'),
    ),
    'cyanora': (
        primary-down: palette-var('cyanora-down'),
        primary: palette-var('cyanora'),
        tap-highlight: palette-var('cyanora-weak'),
        secondary: palette-var('springtime'),
        tertiary: palette-var('coal-mine-canary'),
    ),
    'turquoise': (
        primary-down: palette-var('turquoise'),
        primary: palette-var('turquoise'),
        tap-highlight: palette-var('turquoise'),
        secondary: palette-var('turquoise'),
        tertiary: palette-var('turquoise'),
    ),
    'future-fuscia': (
        primary-down: palette-var('future-fuscia-down'),
        primary: palette-var('future-fuscia'),
        tap-highlight: palette-var('future-fuscia-weak'),
        secondary: palette-var('amethyst'),
        tertiary: palette-var('coal-mine-canary'),
    ),
    'coal-mine-canary': (
        primary-down: palette-var('coal-mine-canary-down'),
        primary: palette-var('coal-mine-canary'),
        tap-highlight: palette-var('coal-mine-canary-weak'),
        secondary: palette-var('apricot'),
        tertiary: palette-var('future-fuscia'),
    ),
    'springtime': (
        primary: palette-var('springtime'),
        primary-down: palette-var('springtime-down'),
        tap-highlight: palette-var('springtime-weak'),
        secondary: palette-var('springtime'),
        tertiary: palette-var('springtime'),
    ),
    'apricot': (
        primary: palette-var('apricot'),
        primary-down: palette-var('apricot-down'),
        tap-highlight: palette-var('apricot-weak'),
        secondary: palette-var('apricot'),
        tertiary: palette-var('apricot'),
    ),
    'amethyst': (
        primary: palette-var('amethyst'),
        primary-down: palette-var('amethyst-down'),
        tap-highlight: palette-var('amethyst-weak'),
        secondary: palette-var('amethyst'),
        tertiary: palette-var('amethyst'),
    ),
    'midnight': (
        primary-down: palette-var('before-midnight'),
        primary: palette-var('midnight'),
        tap-highlight: palette-var('midnight-weak'),
    ),
);

@mixin set-theme-variables($key) {
    $theme: map-get($themes, $key);

    @each $key, $value in $theme {
        --theme-#{$key}: #{$value};
    }
}

@function theme-var($color) {
    @return var(--theme-#{$color});
}

// Creates [data-theme=$name] selectors used and applied by `withTheme`
@mixin theme-classes {
    @each $theme-key, $theme in $themes {
        [data-theme='#{$theme-key}'] {
            @include set-theme-variables($theme-key);

            *::selection {
                background-color: var(--theme-primary);
            }
        }
    }
}
