/** Gaps */
$gap-xx-large: 144px;
$gap-x-large: 72px;
$gap-60px: 60px;
$gap-larger: 48px;
$gap-large: 36px;
$gap-medium-large: 30px;
$gap-medium: 24px;
$gap-small-medium: 20px;
$gap-small: 16px;
$gap-x-small: 8px;
$gap-xx-small: 5px;

/** Fonts */
$font-size-default: 18px;

/** Line heights */
$line-height-small: 18px;
$line-height-default: 20px;
$line-height-medium: 23px;
$line-height-large: 34px;
$line-height-x-large: 40px;
$letter-spacing-small: -0.3px;

/** Borders */
$border-slim: 1px;
$border-medium: 2px;
$border-thick: 4px;

/** Box */
$box-border-radius: 10px;

/** Header */
$header-logo-height: 50px;
$header-logo-height-mobile: 40px;

$header-logo-width: 103px;
$header-logo-width-mobile: 52px;

$header-button-width: 100px;
$header-burger-width: 29px;

/** Section */
$section-image-width: 320px;
$section-image-height: 300px;
$section-video-width: 375px; // non 16:9 ratio videos will probably look ugly
$section-video-height: 211px;

/** Spinner */
$spinner-thickness: 5px;
$spinner-sizes: (
    large: $gap-large,
    medium: $gap-medium,
    small: $gap-small,
);

/** Thumbnail */
$thumbnail-height: 150px;

/** Review */
$review-star-height: 40px;
$review-star-width: 232px;
$review-app-store-rating-width: 270px;

/** Forms */
$form-section-form-max-width: 600px;

/** Input/Button */
$control-border-radius-medium: 25px;
$control-border-radius-small: 15px;
$control-height-medium: 40px;
$control-height-small: 28px;
$control-min-width: 75px;
$control-prefix-length-medium: 80px;
$control-prefix-length-small: 55px;
$control-width-medium: 250px;
$control-width-small: 180px;
$date-input-width: 160px;
$ssn-input-width: 49px;
$full-ssn-input-width: 112px;
$state-input-width: 51px;
$zip-input-width: 64px;

/** Get app form */
$get-app-form-width-medium: 360px;
$get-app-form-height-medium: 72px;
$get-app-form-width-small: auto;
$get-app-form-height-small: auto;

/** List */
$list-icon-size: 22px;
$list-icon-gap: 13px;

/** Icons */
$icon-size-xx-small: 9px;
$icon-size-x-small: 13px;
$icon-size-small: 20px;
$icon-size-medium: 40px;
$icon-size-large: 60px;
$icon-size-x-large: 80px;
$icon-size-xx-large: 130px;
$press-logo-height: 40px;
$investor-logo-height: 49px;

/** Announcements */
$announcement-image-large-height: 49px;
$announcement-image-small-height: 41px;

/** Avatars */
$avatar-border-width: 2px;
$avatar-sizes: (
    medium: $icon-size-medium,
    large: $icon-size-x-large,
    xlarge: $icon-size-xx-large,
);

/** Profile */
$profile-card-height: 500px;
$profile-card-gif-height: 200px;
$profile-card-width: 436px;
$profile-card-radius: 20px;
$profile-card-avatar-top: -80px;
$profile-payment-padding: 50px 60px;
$profile-payment-gaps: 15px 0 47px;
$profile-input-height: 34px;
$profile-payment-amount-height: 75px;

/** Modals **/
$modal-padding-desktop: 40px 50px;
$modal-padding-mobile: 40px 30px;
$modal-width-small: 255px;
$modal-width-medium: 345px;

// header
$mobile-header-top: 0;
$header-bar-height: space-val(17);
$mobile-header-height: $mobile-header-top + $header-bar-height;
$mobile-header-banner-height: 100;

$desktop-header-top: space-val(0);
$desktop-header-height: $desktop-header-top + $header-bar-height;
$desktop-header-banner-height: 70;

$header-cta-height: 34px;

// stairs
$mobile-stair-size: space(9);
$desktop-stair-size: space(21);

// buttons
$button-padding: 11px 24px;
$button-padding-small: 11px 8px;

// copy blocks
$copy-max-width-small: 330px;
$copy-max-width-smedium: 367px;
$copy-max-width-medium: 414px;
$copy-max-width-large: 500px;
$copy-max-width-xlarge: 665px;
$copy-max-width-2xlarge: 1000px;

// heading blocks
$heading-max-width-small: 628px;
$heading-max-width-medium: 846px;
$heading-max-width-large: 975px;
$heading-max-width-xlarge: 1200px;

// utility
$card-border-radius: 12px;
$max-border-radius: 9999px;

// policies
$sidebar-padding: 0 $gap-larger;
$sidebar-padding-tablet: 0 40px;
$sidebar-padding-mobile: 0 20px;

// careers
$greenhouse-iframe-height: 1250px;

// tooltip
$tooltip-min-width: 310px;
$tooltip-arrow-size: 10px;

// snackbars
$snackbar-max-width: 600px;

/** spacing system, multiples of 4 */
@function space-val($multiple) {
    @return 4 * $multiple;
}

@function space($multiple) {
    @return #{space-val($multiple)}px;
}
